﻿/* GLOBAL */
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  /* Global scroll hidden */
  overflow: hidden;
}

/* LOGIN */
#login-container {
  padding: 20px;
}

#login-block {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: auto;
  margin-top: 200px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
}

#login-title {
  width: 100%;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  display: block;
}

.center {
  margin: auto;
  width: 100%;
}

.vertical-btn + .vertical-btn {
  margin-top: 10px;
}

/* CONATAINER */
.container {
  padding: 20px 20px;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
}

.content-page {
  padding: 0px;
  overflow-x: hidden;
}

.content-scroll-user-profile {
  max-height: 85vh;
}

.content-scroll {
  /* overflow-x: hidden; */
  /* max-height: calc(100vh - 19vh); */
  max-height: 81vh;
}

.icon-badge-container {
  padding: 0px;
}

/* TODO: */
.scheduler .center {
  margin: auto;
  width: 100%;
  /* border: 3px solid green; */
  /* padding: 10px; */
}

.table-border {
  border: 1px solid gray;
}

.appointment-tooltip-container > table {
  display: flex;
  justify-content: left;
  font-size: 17px;
  font-weight: 600;
  color: white;
  text-align: left;
  /* background-color: orangered;/ */
  margin: -10px;
  padding: 20px;
  /* text-transform: uppercase; */
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

/* .dx-list-item-content {
  background-color: orangered;
  padding: 0px;
} */

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#toolbar {
  background-color: rgba(191, 191, 191, 0.15);
  padding: 5px 10px;
}

.sl-grid-main {
  max-height: 80%;
}

.dx-list-item-icon-container,
.dx-toolbar-before {
  width: 36px;
  padding-right: 0 !important;
  text-align: center;
}

/* .dx-list-item-content {
  padding-left: 10px !important;
} */

.dx-button {
  background-color: rgba(191, 191, 191, -0.15);
  border: none;
}

/* .panel-list {
  z-index: 100;
  background-color: orangered;
} */

.dx-drawer-expand.dx-drawer-right .panel-list {
  float: right;
}

.panel-list .dx-list-item {
  color: #fff;
  border-top: 1px solid rgba(221, 221, 221, 0.2);
}

.panel-list .dx-list-item .dx-icon {
  color: #fff !important;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.options-container {
  display: flex;
  align-items: center;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  display: inline-block;
  margin-right: 50px;
}

label {
  font-weight: bold;
}

#content {
  height: 100%;
  padding: 20px 20px;
}

#content h2 {
  font-size: 26px;
}

/* NOT USED */
/* .sl-grid-main {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */

.toolbar-label,
.toolbar-label > b {
  font-size: 16px;
}

.hidden {
  visibility: hidden;
}

.shown {
  visibility: visible;
}

#tabs {
  vertical-align: top;
}

.tabs {
  height: 40px;
}

.tabsContainer {
  background-color: #2f2f38;
  height: 40px;
}

/* CUSTOM ICON */
.dx-icon-custom-style {
  font-size: 18px;
}

/* BADGE */
.icon-badge-group .icon-badge-container {
  display: inline-block;
  margin: 0px auto;
}

.icon-badge-group .icon-badge-container:first-child {
  margin-left: 0;
}

.icon-badge-container {
  margin-top: 0px;
  position: relative;
  display: inline-block;
}

.icon-badge-icon {
  font-size: 24px;
  position: relative;
}

.icon-badge {
  background-color: red;
  font-size: 10px;
  font-weight: 700;
  color: white;
  text-align: center;
  box-sizing: border-box;
  border-radius: 40px 40px;
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 2px 6px;
}

.notification-list-container {
  min-height: 440px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: black;
}

.notification-message {
  min-height: 30px;
  height: auto;
  font-size: small;
  background-color: black;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  position: absolute;
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: orangered;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/*.dx-menu-item {
    background-color: white
}

.dx-menu-item.dx-state-focused {
    background-color: whitesmoke
}  */

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI',
    'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.disable-date,
.dinner {
  height: 100%;
  width: 100%;
}

.disable-date {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #c52931;
}

.dinner {
  background: rgba(102, 101, 100, 0.2);
}

#scheduler .dx-scheduler-cell-sizes-horizontal {
  width: 30px;
}

#scheduler .dx-scheduler-cell-sizes-vertical {
  height: 40px;
}

#addAttachment {
  border: 1px solid #d3d3d3;
  /* margin: 20px 20px 0 20px; 
  padding: 0px;  */
}

.dx-fileuploader-files-container {
  padding: 0px;
  width: 0px;
  height: 0px;
}

/* Padding */
.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}

/* Margin */
.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.m40 {
  margin: 40px;
}

.m50 {
  margin: 50px;
}
