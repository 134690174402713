﻿ html, body {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#login-container {
  padding: 20px;
}

#login-block {
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-shadow: 0 4px 8px 4px #0000001a;
}

#login-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: xx-large;
  font-weight: bold;
  display: block;
}

.center {
  width: 100%;
  margin: auto;
}

.vertical-btn + .vertical-btn {
  margin-top: 10px;
}

.container {
  padding: 20px;
  box-shadow: 0 4px 8px 4px #0000001a;
}

.content-page {
  padding: 0;
  overflow-x: hidden;
}

.content-scroll-user-profile {
  max-height: 85vh;
}

.content-scroll {
  max-height: 81vh;
}

.icon-badge-container {
  padding: 0;
}

.scheduler .center {
  width: 100%;
  margin: auto;
}

.table-border {
  border: 1px solid gray;
}

.appointment-tooltip-container > table {
  color: #fff;
  text-align: left;
  justify-content: left;
  margin: -10px;
  padding: 20px;
  font-size: 17px;
  font-weight: 600;
  display: flex;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#toolbar {
  background-color: #bfbfbf26;
  padding: 5px 10px;
}

.sl-grid-main {
  max-height: 80%;
}

.dx-list-item-icon-container, .dx-toolbar-before {
  width: 36px;
  text-align: center;
  padding-right: 0 !important;
}

.dx-button {
  background-color: #bfbfbf00;
  border: none;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
  float: right;
}

.panel-list .dx-list-item {
  color: #fff;
  border-top: 1px solid #ddd3;
}

.panel-list .dx-list-item .dx-icon {
  color: #fff !important;
}

.options {
  background-color: #bfbfbf26;
  padding: 20px;
}

.options-container {
  align-items: center;
  display: flex;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  margin-right: 50px;
  display: inline-block;
}

label {
  font-weight: bold;
}

#content {
  height: 100%;
  padding: 20px;
}

#content h2 {
  font-size: 26px;
}

.toolbar-label, .toolbar-label > b {
  font-size: 16px;
}

.hidden {
  visibility: hidden;
}

.shown {
  visibility: visible;
}

#tabs {
  vertical-align: top;
}

.tabs {
  height: 40px;
}

.tabsContainer {
  height: 40px;
  background-color: #2f2f38;
}

.dx-icon-custom-style {
  font-size: 18px;
}

.icon-badge-group .icon-badge-container {
  margin: 0 auto;
  display: inline-block;
}

.icon-badge-group .icon-badge-container:first-child {
  margin-left: 0;
}

.icon-badge-container {
  margin-top: 0;
  display: inline-block;
  position: relative;
}

.icon-badge-icon {
  font-size: 24px;
  position: relative;
}

.icon-badge {
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  background-color: red;
  border-radius: 40px;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: -6px;
  left: 10px;
}

.notification-list-container {
  min-height: 440px;
  height: auto;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
}

.notification-message {
  min-height: 30px;
  height: auto;
  background-color: #000;
  font-size: small;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #ff4500;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.long-title h3 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Segoe UI Light, Helvetica Neue Light, Segoe UI, Helvetica Neue, Trebuchet MS, Verdana;
  font-size: 28px;
  font-weight: 200;
}

.disable-date, .dinner {
  height: 100%;
  width: 100%;
}

.disable-date {
  color: #c52931;
  background-image: repeating-linear-gradient(135deg, #f443361a, #f443361a 4px, #0000 4px 9px);
}

.dinner {
  background: #66656433;
}

#scheduler .dx-scheduler-cell-sizes-horizontal {
  width: 30px;
}

#scheduler .dx-scheduler-cell-sizes-vertical {
  height: 40px;
}

#addAttachment {
  border: 1px solid #d3d3d3;
}

.dx-fileuploader-files-container {
  width: 0;
  height: 0;
  padding: 0;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.m40 {
  margin: 40px;
}

.m50 {
  margin: 50px;
}

/*# sourceMappingURL=index.3559a837.css.map */
